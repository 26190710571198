import React, { useRef, useContext, useState, useMemo, useEffect } from "react"
import ReactMarkdown from 'react-markdown'
import {motion } from "framer-motion";
import {Product, ProductVariant} from "../models/product"
import {FiMinus, FiPlus } from "react-icons/fi";
import {CartContext} from '../cart';
import {SelectOption} from "../components/select";
import {useIsMobile, numberWithCommas} from "../utils";
import {BlackLine} from "../components/line";
import { toast } from 'react-toastify';
import rehypeRaw from 'rehype-raw'
import {Radio } from "../components/radio";
import { SEO } from '../components/seo';

const ProductImages = ({ coverUrl, isMobile, imageUrls = [] }) => {
    const [currentCover, setCurrentCover] = useState(coverUrl);
    const [subImages, setSubImages] = useState<string[]>(imageUrls);

    return (
    <div
        style={{
            ...(
                isMobile ? {
                    width: '100%',
                    display: 'flex'
                } : {
                    display: 'table-cell',
                    width: 500,
                }
            ),
            flexDirection: 'column',
            gap: 8
        }}
    >
        <img src={currentCover}
             style={{ width: '100%', maxHeight: 500, objectFit: "cover",
              ...(!isMobile && { marginBottom: '8px' }),
        }}
        />
        {
          imageUrls.length > 0 &&
            <div style={{ width: isMobile ? '100%' : 500, display: 'flex', gap: 8}}>
              {
                [coverUrl, ...subImages].map((v, i) => 
                <div style={{ flex: '1 1 0', cursor: 'pointer', maxWidth: 120 }}
                  onClick={() => {
                    setCurrentCover(v);
                  }}
                >
                  <img src={v} style={{ width: '100%' }} />
                </div>
                )
              }
            </div>
        }
    </div>
  )
};
const ProductDescription = ({ description }) => (
  <div
      style={{
          maxWidth: '100%',
          padding: '1rem 0',
      }}
  >
    <ReactMarkdown 
      rehypePlugins={[rehypeRaw]}
      components={{
        p: (props) => <div style={{ 
          margin: '1.625px 0px', textAlign: 'justify',
          fontSize: 16,
          fontWeight: 400,
          color: "#4a4a4a",
          lineHeight: 1.8,
        }} {...props} />
      }}
      children={description} />
  </div>
);
const DetailImage = ({ url, isMobile, width }) => url ? (
  <div
      style={{
          width,
          ...(
              isMobile ? {
              } : {
                  display: 'table-cell',
                  verticalAlign: 'middle',
                  maxWidth: 800,
                  padding: '0 2rem',
              }
          ),
      }}
  >
      <img src={url}
           style={{ width: '100%', objectFit: "cover",}}
      />
  </div>
) : <div />;

const GridLabel = ({ label }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      textWrap: 'nowrap',
      marginRight: 24,
    }}
  >
    {label}
  </div>
);

const ProductTemplate = ({pageContext: {productData}}) => {
    const product = useMemo(() => Product.fromShopify(productData), [productData]);
    const [options, setOptions] = useState<string[]>(product.options.map((o) => o.values[0]
  ));
    const [spec, setSpec] = useState<ProductVariant | null>(null);
    const [quantity, setQuantity] = React.useState(1);
    const {addItem} = useContext(CartContext);

    const [roast, setRoast] = useState<string | null>('淺焙');
    const [gear, setGear] = useState<string | null>('手沖');
    const [grind, setGrind] = useState<string | null>('不用磨');
    const [sour, setSour] = useState<number | null>('1');

    const {screenWidth} = useIsMobile();
    const isMobile = screenWidth < 1000;
    const isSmallScreen = screenWidth < 475;
    const  isSubscription = product.productType === '咖啡訂閱';
    const [isGridCollapsed, setIsGridCollapsed] = useState(false);
    const priceEl = useRef(null);
    const infoEl = useRef(null);

    const getVariants = () => 
      product?.variants.filter(({ name }) => name !== 'Default Title');
    const variants = getVariants();

    useEffect(() => {
      setSpec(options.reduce((r, o) => {
        return r.filter((v) => v.name.includes(o));
      }, variants)[0]);
    }, [options]);

    useEffect(() => {
      if (priceEl.current) {
        if( priceEl.current.clientWidth < 450) {
          setIsGridCollapsed(true);
        } else {
          setIsGridCollapsed(false);
        }
      }
    }, [screenWidth]);

    const Main = () => (
        <div
          ref={infoEl}
            style={{
            flexDirection: 'column',
            padding: isMobile ? '8px 0' : '0 2rem',
            alignItems: 'start',
            ...(
                isMobile ? {
                    display: 'flex',
                    maxWidth: '100%',
                } : {
                    verticalAlign: 'top',
                    display: 'table-cell',
                    maxWidth: 800,
                }
            ),
        }}>
                <span
                  className="title2"
                    style={{
                        padding: '0.5rem 0',
                    }}
                >{product.name}</span>
      {product.engName && <div
                    className="title3"
                    style={{
                        padding: '0.5rem 0',
                    }}
                >{product.engName}</div>
      }
            <div style={{height: 8}}></div>
            <BlackLine/>
            <div style={{height: 8}}></div>
            <ProductDescription description={product.description} />
            <div
              style={{
                width: '100%',
                display: 'grid',
                gridGap: '16px',
                gridTemplateColumns: 
                isGridCollapsed ? 'auto' : 'minmax(48px, min-content) auto',
              }}
            >
              <GridLabel label="價格" />
              <div
                ref={priceEl}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      fontSize: 28,
                    }}
                  >
                    NT
                    {
                      spec != null ? (
                              `$${numberWithCommas(spec.price)}`
                          ) :
                          product.minPrice == product.maxPrice ? (
                              `$${numberWithCommas(product.minPrice)}`
                          ) : (

                              `$${numberWithCommas(product.minPrice)} - $${numberWithCommas(product.maxPrice)}`
                          )
                    }
                  </div>
                  {
                    spec != null && spec.compareAtPrice != null && (
                      <div
                        style={{
                          marginLeft: 16,
                          color: '#888',
                          textDecoration: 'line-through',
                        }}
                      >
                        原價 NT${numberWithCommas(spec.compareAtPrice)}
                      </div>
                    )
                  }
                </div>
              </div>
              {
                product.options.map((o) => <>
                  <GridLabel label={o.name} />
                  <div style={{
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                  }}>
                      {
                          o.values.map((e) =>
                                  <SelectOption
                                      value={e}
                                      text={e}
                                      selected={options.includes(e)}
                                      onClick={() => {
                                        setOptions(options.filter((i) => !o.values.includes(i)).concat(e));
                                      }}
                                  />
                          )
                      }
                  </div>
                </>)
              }
              {
                isSubscription && <>
                  <GridLabel
                    label="沖煮方式"
                  />
                  <div style={{
                    flexGrow: 1,
                    gap: 8,
                    display: 'flex',
                    flexFlow: 'row wrap',
                  }}>
                    {
                      ['手沖', '濾壓壺', '愛樂壓', '濃縮'].map((v) =>
                        <SelectOption
                            value={v}
                            text={v}
                            selected={gear === v}
                            onClick={() => {
                              setGear(v);
                            }}
                        />
                      )
                    }
                  </div>
                  <GridLabel
                    label="烘焙程度"
                  />
                  <div style={{
                    flexGrow: 1,
                    gap: 8,
                    display: 'flex',
                    flexFlow: 'row wrap',
                  }}>
                    {
                      [
                      ...(!product.name.includes('濃縮') ? ['淺焙'] : []), 
                      '淺中焙', '中焙', '深焙'].map((v) =>
                        <SelectOption
                            value={v}
                            text={v}
                            selected={roast === v}
                            onClick={() => {
                              setRoast(v);
                            }}
                        />
                      )
                    }
                  </div>
                  <GridLabel
                    label="研磨程度"
                  />
                  <div style={{
                    flexGrow: 1,
                    gap: 8,
                    display: 'flex',
                    flexFlow: 'row wrap',
                  }}>
                    {
                      ['不用磨', '手沖', '愛樂壓', '濃縮'].map((v) =>
                        <SelectOption
                            value={v}
                            text={v}
                            selected={grind === v}
                            onClick={() => {
                              setGrind(v);
                            }}
                        />
                      )
                    }
                  </div>
                  <GridLabel
                    label="酸度偏好"
                  />
                  <div style={{
                    flexGrow: 1,
                    gap: 8,
                    display: 'flex',
                    flexFlow: 'nowrap',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    margin: '8px 0px',
                  }}>
                    弱
                    {
                      ['1', '2', '3', '4', '5'].map((v) =>
                        <Radio
                            selected={sour}
                            onChange={() => {
                                setSour(v);
                            }}
                            value={v}
                            style={{ margin: 0 }}
                        />
                      )
                    }
                    強
                  </div>
                </>
              }
                <GridLabel
                  label="數量"
                />
                <div style={{
                    border: '1px solid #ccc',
                    display: 'flex',
                    backgroundColor: '#fff',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <div style={{
                        padding: '0.5rem',
                        cursor: 'pointer',
                    }}
                         onClick={() => {
                             setQuantity(quantity + 1);
                         }}
                    >
                        <FiPlus/>
                    </div>
                    <div style={{
                        padding: '0.5rem',
                    }}>
                        {quantity}
                    </div>
                    <div style={{
                        padding: '0.5rem',
                        cursor: 'pointer',
                    }}
                         onClick={() => {
                             if (quantity > 1) {
                                 setQuantity(quantity - 1);
                             }
                         }}
                    >
                        <FiMinus/>
                    </div>
                </div>
            </div>
            <div 
              className="my-btn"
              style={{
                flexGrow: 1,
                border: '1px solid #000',
                marginTop: '24px',
                padding: '0.5rem',
                textAlign: 'center',
                cursor: 'pointer',
                borderRadius: 4,
                width: '100%'
              }}
              data-quantity={quantity.toString()}
              data-item_id={spec?.id}
              data-item_name={product?.name + ' ' + spec?.name}
              data-price={spec?.price}
              onClick={() => {
                toast('成功加入購物車' );
                addItem([
                  {
                    variantId: spec!.id,
                    quantity,
                    ...(isSubscription ? {
                      customAttributes: [
                        {
                           key: '烘焙',
                          value: roast,
                        },
                        {
                           key: '研磨',
                          value: grind,
                        },
                        {
                           key: '酸度',
                          value: sour,
                        },
                        {
                           key: '沖煮',
                          value: gear,
                        },
                      ]
                    } : {})
                  }
                ]);
              }}
            >
              加入購物車
            </div>
        </div>
    );

    const DetailText = () => product.detailText ? (
      <div style={{
        ...(
            isMobile ? {
                display: 'flex',
                maxWidth: '100%',
                flexDirection: 'column',
            } : {
                verticalAlign: 'top',
                display: 'table-cell',
                maxWidth: 500,
            }
        ),
      }}>
        <ReactMarkdown 
          components={{
            p: (props) => <p style={{ textAlign: 'justify',
fontSize: 16,
          fontWeight: 400,
          color: "#4a4a4a",
}} {...props} />,
            h2: (props) => <h2 style={{ 
              textAlign: 'justify',
              fontSize: '2rem',
              fontWeight: 500,
              color: '#1e1e1e'
            }} {...props} />,
            h1: (props) => <h2 style={{ 
              textAlign: 'justify',
              fontSize: '2rem',
              fontWeight: 500,
              color: '#1e1e1e'
            }} {...props} />,
          }}
          children={product.detailText} />
      </div>
      ) : <div />;



    const motionVariants = {
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 1,
                ease: "easeInOut",
            },
        },
        hidden: {
            opacity: 0,
            transition: {
                duration: 0.6,
                ease: "easeInOut",
            },
        },
    };
    const motionProps = {
        animate: 'visible',
        initial: 'hidden',
        //exit: 'hidden',
        variants: motionVariants,
    }

  return (<div>
        <motion.div
            {...motionProps}
            style={{
                maxWidth: '88%',
                alignItems: 'start',
                margin: isMobile ? '24px auto' : '0 auto',
                padding: isMobile ? '0px' : '24px',
                justifyContent: 'center',
                ...(
                    isMobile ? {
                        display: 'flex',
                        flexDirection: 'column',
                    } : {
                        display: 'table',
                    }
                )
            }}
        >
            {
                isMobile ? [
                <ProductImages isMobile={isMobile} 
                      imageUrls={product.imageUrls}
                  coverUrl={product.coverUrl} />,
                    <Main/>
                ] : [
                    <Main/>,
                    <div style={{ width: 24}} />,
                    <ProductImages 
                      isMobile={isMobile} coverUrl={product.coverUrl} 
                      imageUrls={product.imageUrls}
                    />
                ]
            }
        </motion.div>
        <div style={{ height: 16 }} />
        <motion.div
            {...motionProps}
            style={{
                alignItems: 'start',
                margin: isMobile ? '24px auto' : '0 auto',
                padding: '24px',
                justifyContent: 'center',
                ...(
                    isMobile ? {
                        display: 'flex',
                        flexDirection: 'column',
                    } : {
                        display: 'table',
                        paddingBottom: '24px',
                    }
                ),
                marginBottom: '48px',
            }}
        >
            {
                isMobile ? [
                    <DetailImage isMobile={isMobile} url={product.detailImageUrl} width="100%" />,
                    <div style={{ height: 24}} />,
                    <DetailText/>
                ] : [
                    <DetailImage isMobile={isMobile} url={product.detailImageUrl} width={infoEl.current?.clientWidth || "500px"} />,
                    <div style={{ width: 24}} />,
                    <DetailText/>
                ]
            }
        </motion.div>
    </div>);
}

export default ProductTemplate;
export const Head = ({ pageContext: { productData }}) => {
  const product = Product.fromShopify(productData);
  return (
    <SEO
      title={product.name}
      description={product.description?.replace(/<[^>]+>/g, '')?.trim() || ''}
      image={product.coverUrl}
    >
    <script type="application/ld+json">
    {`{
      "@context" : "https://schema.org",
      "@type" : "Product",
      "name" : "${product.name}",
      "description" : "${product.description?.replace(/<[^>]+>/g, '')?.trim() || ''}"
    }`}
    </script>
    </SEO>
  );
}
