import React from "react";

export const BlackLine = () =>
    <hr
        style={{
            height: 1,
            color: 'black',
            background: 'black',
            width: '100%',
        }}
    />
export const DottedLine = () =>
    <hr
        style={{
            height: 1,
            width: '100%',
            border: "none",
            background: 'none',
            borderTop: "2px dotted black",
        }}
    />
