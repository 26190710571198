import React from "react";

export const SelectOption = ({value, text, selected = false, onClick}) => (
    <div onClick={() => {
        onClick(value);
    }} style={{
        width: 'fit-content',
        color: selected ? "#fff" : "#000",
        backgroundColor: selected ? "#000" : "transparent",
        padding: "0.5rem",
        cursor: "pointer",
        paddingTop: "0.3rem",
        paddingBottom: "0.3rem",
        paddingRight: "2rem",
        paddingLeft: "2rem",
        border: "1px solid #000",
        borderRadius: '6px',
        flexWrap: 'nowrap',
        margin: '8px 8px 8px 0px',
    }}>
        {text}
    </div>
)
